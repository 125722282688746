export const defaultId = process.env.REACT_APP_DEFAULT_COMPCODE;
export const zero = process.env.REACT_APP_ZERO_COMPCODE;                // encoded string version of 0.
// export const defaultId = 'UOWERJLJFOAISJ';
export const ePharmaId = process.env.REACT_APP_E_PHARMA_COMPCODE;
export const amNursingId = process.env.REACT_APP_AM_NURSING_HOME_COMPCODE;
export const blessId = process.env.REACT_APP_BLESS_HOSPITAL_COMPCODE;
export const sumaangId = process.env.REACT_APP_SUMAANG_COMPCODE;
export const bhsId = 'process.env.REACT_APP_DEFAULT_COMPCODE';
export const BSN_ID = process.env.REACT_APP_BSN_COMPCODE;
export const ASTHA_ID = process.env.REACT_APP_ASTHA_SEVA_SADAN_COMPCODE;                                       
export const TAKE_HOME_ID = process.env.REACT_APP_TAKE_HOME_COMPCODE;    
export const XYZ_ID = process.env.REACT_APP_XYZ_COMPCODE;    
export const HAYATT_ID = process.env.REACT_APP_HAYATT_COMPCODE;    


export const initSiteData = {isLoading: true, itemMasterCollection: [], ParentCategoryList: [{Value: ''}], LinkSubCategoryList: []};

export const currentVersion = '0012';