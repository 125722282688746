import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { breadCrumbAction, modalAction, loaderAction } from '../../../actions';
// import { Link } from 'react-router-dom';
// import { ConnectedCartCardM } from './mobileView/cards';
import { ConnectedMyOrdersCard } from './cards';
import { getFrom, Spinner } from './utilities';
import { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';


const Orders = ({ breadCrumbAction, userInfo, compCode, isLoggedIn, modalAction, loaderAction, globalData }) => {

  useEffect(() => {
		breadCrumbAction({links: [{name: 'Home', link: '/'}, {name: 'My Orders', link: '/myOrderPage'}], activeLink: '/myOrderPage'});
	},[breadCrumbAction])

  const [tabActive, setTabActive] = useState('active');
  const [myOrderData, setMyOrderData] = useState({loading: false, data: {OrderList: []}, err: {status: false, msg: ''}});

  const history = useHistory();


  const getMyOrders = useCallback( async (params, locationId) => {
      const res = await getFrom(`${process.env.REACT_APP_BASE_URL}/api/Pharma/Get?CID=${compCode}&PID=${params}&Type=${tabActive}&LOCID=${locationId}`, {}, setMyOrderData);
                                        //                        /api/Pharma/Get?CID=TcbqtUi5nB%2BX6FL5ySfFyg==&PID=829049&Type=active&ID=1
      if (res) {                                              
          setMyOrderData(res); 
      } else {
          console.log('No data received');
      }
  },[tabActive])                                                               // Adding tabActive as dependency will call getMyOrders whenever tabActive is changed with current tab name.

  useEffect(() => {
    if (!isLoggedIn) return history.push('/');
    getMyOrders(userInfo.PartyCode, globalData.location.LocationId);
  },[compCode, getMyOrders, isLoggedIn, history, userInfo.PartyCode, globalData.location.LocationId])


  const noItemFound = () => (
    <div className="card mb-4">
        <div className="card-header">
            <h5 className="mb-0">Orders</h5>
        </div>
        <div className="card-body cart">
            <div className="col-sm-12 empty-cart-cls text-center py-3 py-lg-5">
                <img src="/assets/img/ePharma/emptyCart.png" className="img-fluid mb-4 me-4" alt="empty_cart"/>
                <h5>You have no {tabActive} orders</h5>
                <Link to='/' className='continue-button' style={{margin: '1rem 0 1.5rem'}}>Place an Order</Link>
            </div>
        </div>
    </div>
  )

  const cancelOrder = async (id) => {
    loaderAction(true);
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/Pharma/${id}`, {});
    loaderAction(false);
    if (res) {
        console.log(res);
        history.push('/showErrorPage');                             // Redirecting to error page so that user can click back to orders button to be again
        modalAction('ORDER_CANCELLED_MODAL', true)                  // redirected to my order page so it can relaod itself with latest changes.
    }                          
  }

  const delivery = [
    { title: 'Dispatched', date: '30/09/2024', time: '5:16 PM' },
    { title: 'Out for Delivery', date: '31/09/2024', time: '6:30 PM' },
    { title: 'Order Delivered', date: '01/10/2024', time: '7:56 PM' },
  ]

  const renderTabs = (data) => {
    if (data.loading) {
      return <Spinner min_height='25rem' fSize='2.5rem'/>;
    } else if (data.err.status) {
      return <div className='text-center my-5'><h2 className="text-danger mark">An error occured, please try again later. Error code: <span className='text-dark'>{data.err.msg}</span></h2></div>;
    } else if (data.data.OrderList.length === 0) {
      return noItemFound();
    } else {
        return data.data.OrderList.map(order => (
            <div className="order-section" key={order.BillId}>
                <div className='card-title'>
                    <h5><i className='bx bx-gift'></i> <span className='ms-2 me-4'>ORDER ID: {order.VchNo}</span> <span style={{fontFamily: 'Lato', fontSize: '0.9em', color: '#3c3c3c'}}>({Object.values(order.SalesDetailsList).length} Items)</span></h5>
                </div>
                <div className="row row-cols-1 row-cols-lg-2 order-details mb-4">

                    <div className="col">
                        <div>
                            <h6>Order ID: </h6>
                            <h6>{order.VchNo}</h6>
                        </div>
                        <div>
                            <h6>Order Date: </h6>
                            <h6>{order.VchDate.slice(0, 10).split('-').reverse().join('-')}</h6>
                        </div>
                    </div>
                    <div className="col">
                        <div>
                            <h6>Name: </h6>
                            <h6>{order.PartyName}</h6>
                        </div>
                        <div>
                            <h6>Payment Method: </h6>
                            <h6>{order.PaymentMethod}</h6>
                        </div>
                    </div>

                    <div className="col">
                        <div>
                            <h6>Order Value: </h6>
                            <h6>₹ {parseFloat(order.Amount).toFixed(2)}</h6>
                        </div>
                    </div>
                    <div className="col">
                        <div>
                            <h6>Delivery Address: </h6>
                            <h6>{order.PartyAddress}</h6>
                        </div>
                    </div>

                    {tabActive === 'cancelled' ? '' : <div className="col">
                        <div className='align-items-start'>
                            <h6>Order Status: </h6>
                            {order.OrderStatus ? 
                                <span className='badge badge-pill d-flex align-items-center text-uppercase' style={{background: order.ApprovalStatus === 'Y' ? '#00ad44' : '#009efb', fontSize: '0.8em'}}>{order.OrderStatus}</span>
                            : 
                                <span className='badge badge-pill d-flex align-items-center' style={{background: order.ApprovalStatus === 'Y' ? '#00ad44' : '#009efb', fontSize: '0.8em'}}>{ order.ApprovalStatus === 'Y' ? 'PROCESSED' : 'ORDER PLACED' }</span>
                            }
                            
                        </div>
                        <div className="my-2 align-items-start">
                            <h6>Billing Status: </h6>
                            <span className='badge badge-pill d-flex align-items-center' style={{background: order.BillStatus === 'PENDING' ? '#f29101' : '#00ad44', fontSize: '0.8em'}}>{order.BillStatus}</span>
                        </div>
                    </div>}

                    {tabActive === 'active' && <div className="col mb-3 mb-md-0 mt-2">
                        <div className='btn-box-1'>
                            {order.ApprovalStatus === 'Y' ? '' : <button onClick={(() => cancelOrder(order.BillId))} type="button" className="button add-cart-button" style={{'--cClr': '#ff4d79', '--cBg': '#ff4d7929'}}><i className="bx bxs-trash-alt"></i> Cancel Order</button>}
                            {/* <button type="button" className="button help" style={{'--cClr': '#00a9bf', '--cBg': '#00bcd429'}}><i className="fa fa-handshake-o" aria-hidden="true"></i> Need Help</button> */}
                        </div>  
                    </div>}

                    {tabActive === 'completed' && <div className="col mb-3 mb-md-0">
                        <div className='btn-box-1'>
                            {/* <button type="button" className="button help" style={{'--cClr': '#00a9bf', '--cBg': '#00bcd429'}}><i className="fa fa-handshake-o" aria-hidden="true"></i> Need Help</button> */}
                            <div className="dropdown">
                                <button className="btn btn-secondary dropdown-toggle border-0" style={{color: '#a38300', background: '#f0e4cd', fontWeight: '500'}} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    View Invoice
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{fontSize: '1em'}}>
                                    {order.InvoiceList.map(i => (
                                        <li key={i.BillId}><Link className="dropdown-item" to={`/invoices/${i.BillId}`}>{i.VchNo}</Link></li>
                                    ))}
                                </ul>
                            </div>
                        </div>   
                    </div>}
                    {/* {tabActive === 'cancelled' ? '' : 
                    <div className='delivery-timeline px-0'>
                        {tabActive === 'active' ?<input className='p-0 border-none' type="range" name="vol" min="0" max="100" value={50}></input> : ''}
                        {tabActive === 'completed' ?<input className='p-0 border-none' type="range" name="vol" min="0" max="100" value={100}></input> : ''}
                        <ul>
                            <li className='text-primaryS'>: Packed</li>
                            <li className='text-dangerS'><span>30/09/2024 <b>5:16 PM</b></span>: Out for Delivery</li>
                            <li className='text-successS'><span>30/09/2024 <b>5:16 PM</b></span>: Delivered</li>
                        </ul>
                        <table>
                            <tbody>
                                {delivery.map(i => (
                                    <tr>
                                        <td><span>{i.date}<b>{i.time}</b></span></td>
                                        <td>: {i.title}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>} */}
                </div>
                <div className="row row-cols-1 row-cols-lg-2 gy-4">
                    {Object.values(order.SalesDetailsList).map((orderItem, index) => (
                        <div className="col position-relative" key={index}>
                            <ConnectedMyOrdersCard data={orderItem}/>
                            {tabActive === 'cancelled' && <h2 className="water-mark">Cancelled</h2>}
                        </div>
                    ))}
                </div>                   
            </div>
        ))
    }
  }


  return (
    <div id="myOrder">
      <div className="Shopping-cart-area pb-xs-0 pb-60 pt-4">
        <div className="container">
            <div className="row mb">
                <div className='col-12 myOrder-heading d-flex justify-content-between flex-wrap gap-1'>
                    <h4 onClick={() => modalAction('ORDER_CANCELLED_MODAL', true)}>My Orders..</h4>
                    <ul className='d-flex gap-5 align-items-end list-inline'>
                        <li className={`pb-2 ${tabActive === 'active' && 'active'}`} onClick={() => setTabActive('active')}>Active</li>
                        <li className={`pb-2 ${tabActive === 'completed' && 'active'}`} onClick={() => setTabActive('completed')}>Completed</li>
                        <li className={`pb-2 ${tabActive === 'cancelled' && 'active'}`} onClick={() => setTabActive('cancelled')}>Cancelled</li>
                    </ul>
                </div>
            </div>
 
            <div className="row">
                <div className="tab-content overflow-hidden pt-2">
                    <div id="tabFade-pane-1" className='tab-pane fade show active' role="tabpanel" aria-labelledby="tabFade-1">
                        {renderTabs(myOrderData)}               {/* dynamically changing tab content hence don't need to switch between tabs. */}
                    </div>
                </div>
            </div>            
        </div>
      </div>
    </div>
  )
}

const mapStateToPropsTwo = (state) => {
  return { isMobile: state.isMobile, userInfo: state.userInfo, compCode: state.compCode, isLoggedIn: state.isLoggedIn, globalData: state.globalData };
}

export default connect(mapStateToPropsTwo, {breadCrumbAction, modalAction, loaderAction})(Orders);




// {/* <tr key={index}>
// <td className="li-product-remove"><i onClick={() => removeFromCartAction(item.item.ItemId)} className="fa fa-trash text-primary me-3"></i> <i onClick={() => {removeFromCartAction(item.item.ItemId); addToWishlistAction(item.item);}} className="fa fa-heart text-danger"></i></td>
// <td className="li-product-thumbnail"><Link to={`/productPage/${item.item.ItemId}`}><img src={item.item.ItemImageURL} alt={item.item.Description} style={{maxHeight: '10rem'}}/></Link></td>
// <td className="li-product-name"><Link to="#">{item.item.Description}</Link></td>
// <td className="li-product-price"><span className="amount">₹{item.item.SRate}</span></td>
// <td className="quantity">
//     <label>Quantity</label>
//     <div className="cart-plus-minus">                                                                                                        {/* parseInt will auto-restrict non-number input values. */}
//         <input className="cart-plus-minus-box" onChange={(e) => {if (e.target.value !== '0' && e.target.value !== '') addToCartAction(item.item, parseInt(e.target.value))}} value={item.count} type="text"/>
//         <div onClick={() => {if (item.count !== 1) addToCartAction(item.item, item.count-1)}} className="dec qtybutton"><i className="fa fa-angle-down"></i></div>
//         <div onClick={() => {addToCartAction(item.item, item.count+1)}} className="inc qtybutton"><i className="fa fa-angle-up"></i></div>
//     </div>
// </td>
// <td className="product-subtotal"><span className="amount">₹{(item.count * item.item.SRate).toFixed(2)}</span></td>      
// </tr>                                                         // docs says .toFixed method changes number into string. */}

