import { combineReducers  } from 'redux';
import _ from 'lodash';
import qs from 'query-string';
import { initSiteData } from '../constants';

const queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true, decode: false });
// const compCode = queryString.CID ? queryString.CID : process.env.REACT_APP_DEFAULT_COMPCODE;
const compCode = process.env.REACT_APP_XYZ_COMPCODE // REACT_APP_TAKE_HOME_COMPCODE  // REACT_APP_E_PHARMA_COMPCODE  //   //REACT_APP_BSN_COMPCODE  // REACT_APP_BSN_COMPCODE ;

const compCodeReducer = (state=compCode, action) => {              // Holds Company code of current company.
  if (action.type === 'PARTY_CODE') {
    return action.value;
  }
  return state;
}

const loginStatusReducer = (isLoggedIn=false, action) => {                  // Holds Status (open/close) of Login modal.
  if (action.type === 'LOGIN') {
    return action.value;
  }
  return isLoggedIn;
}

const loaderReducer = (isLoading=false, action) => {                        // Holds Status (open/close) of Global Loader on app.js page.
  if (action.type === 'LOADING') {
    return action.value;
  }
  return isLoading;
}

let currentUser = {
  Salutation: '',
  Name: '',
  EncCompanyId: '',
  PartyCode: '',
  RegMob1: '',
  Gender: '',
  GenderDesc: '',
  Address: '',
  Age: '',
  AgeMonth: '',
  AgeDay: '',
  UserPassword: '',
  Department: {dName: 'All', SubCode: 0},
  // UserType: 'PATIENT',
  UserType: '',
  Qualification: '',
  RegNo: '',
  SpecialistId: 0,
  MPartyCode: '',
  PartyId: 0,
  MemberId: '',
  selectedCompany: {},
  MembersList: {},  
  companyList: [],                     
  selectedMember: {},
  UserId: 0,
  location: {},
  Country: 1,

  State: '3',
  StateName: 'West Bengal',
  City: '',
  Pin: '',
  Address2: '',

  DOB: '',
  AnniversaryDate: '',
  Aadhaar: '',
  IsDOBCalculated: 'N',

  compName: '',
  compAddress: '',
  compState: '',
  compPin: '',
  compPhone1: '',
  compPhone2: '',
  compMail: '',
}

const userInfoReducer = (state=currentUser, action) => {                // Holds all the Informations of User.
  if (action.type === 'USER_INFO') {                                    // *** OldState === newState turns out to be true which causes components to not rerender on change of deep nested properties 
    let newState = Object.assign(state, action.payload);                // or on change of properties that are without any key on them (eg. list of objects without keys).
    return {...newState};                                               // {...newState} or _.deepClone(newState) will clone the given object/state and reproduce a new object/state where           
  }                                                                     // oldState !== {...newState} or _.deepClone(newState) which forces every connected component to re-render with new state.          
  return state;                                                                                                                                                                  
}

const init_compInfo = {COMPNAME: 'XYZ Hospitality Solutions', EncCompanyId: compCode, LogoUrl: '612.png'};      // use recieved compcode to avoid race conditons in pharmacy page, others fields will be updated with api call.

const companyInfoReducer = (state=init_compInfo, action) => {              // Holds the Information of current Company.
  if (action.type === 'COMPANY_INFO') {
    return action.payload;
  }
  return state;
}

const initBookingData = {
  selectedAppnDate: '',                                                 // used to detect active item of date button slider in bookingForm.
  Doctor: {Name: '', SpecialistDesc: '', Qualification: '', Mobile: ''},
  UnderDoctId: '',
  AppointDate: '',
  AppTime: '',
  TimeSlotId: '',
  companyId: ''
}
const bookingDataReducer = (state=initBookingData, action) => {
  if (action.type === 'BOOKING_DATA') {                                 // *** OldState === newState turns out to be true which causes components to not rerender on change of deep nested properties    
    let newState = Object.assign(state, action.payload);                // or on change of properties that are without any key on them (eg. list of objects without keys).
    return {...newState};                                               // {...newState} or _.deepClone(newState) will clone the given object/state and reproduce a new object/state where           
  }                                                                     // oldState !== {...newState} or _.deepClone(newState) which forces every connected component to re-render with new state.          
  return state;  
}

// state={itemMasterCollection: ['loading'], ParentCategoryList: [{Value: ''}], LinkSubCategoryList: []}
const siteDataReducer = (state=initSiteData, action) => {
  if (action.type === 'SITE_DATA') {
    return action.payload;
  } else {
    return state;
  }
}
const initCart = { pharmacy: {}, labTests: {} };
const cartReducer = (cart=initCart, action) => {                        
  let { type, payload, productType } = action;
  if (type==='ADD_ITEM') {
    return {...cart, [productType]: {...cart[productType], [payload.ItemId]: payload }};
  } else if (type==='REMOVE_ITEM') {
    return {...cart, [productType]: _.omit(cart[productType], payload) };                                                        // Pass list and id of target item to remove it from list.
  } else if (type==='DUMP_CART') {
    return {...cart, [productType]: {}};
  } else if (type==='EMPTY_CART') {
    return { pharmacy: {}, labTests: {} };
  }
  return cart;
}

const initWishlist = { pharmacy: {}, labTests: {} };
const wishlistReducer = (wishlist=initWishlist, action) => {                            // Holds Cart items data.
  let { type, payload, productType } = action;
  if (type==='ADD_WISH_ITEM') {
    return {...wishlist, [productType]: {...wishlist[productType], [payload.ItemId]: payload }};
  } else if (type==='REMOVE_WISH_ITEM') {
    return {...wishlist, [productType]: _.omit(wishlist[productType], payload) };                            // Pass list and id of target item to remove it from list.
  } else if (type==='DUMP_WISHLIST') {
    return {...wishlist, [type]: {}};
  } else if (type==='EMPTY_WISHLIST') {
    return { pharmacy: {}, labTests: {} };
  }
  return wishlist;
}

const toastReducer = (state={status: false, item: {}}, action) => {       // Controls the Toast state and data.
  if (action.type === 'SHOW_TOAST') {
    return action.payload;
  }
  return state;
}

const modals = {                                                  // state of all modals.
  LOGIN_MODAL: {state: false, data: {mode: 'PATIENT'}},
  APPN_BOOKING_MODAL: {state: false, data: ''},
  SCHEDULE_MODAL: {state: false, data: ''},
  MEMBER_MODAL: {state: false, data: ''},
  DOC_DETAILS_MODAL: {state: false, data: ''},
  EDIT_USER_MODAL: {state: false, data: ''},
  MEMBER_PROFILE_MODAL: {state: false, data: ''},
  MEMBER_SELECT_MODAL: {state: false, data: ''},
  LABTEST_BOOK_MODAL: {state: false, data: ''},


  // LOGIN_MODAL: {status: false, data: ''},
  // EDIT_USER_MODAL: {status: false, data: ''},

  QUICKVIEW_MODAL: {state: false, data: ''}, 
  CATEGORY_MODAL: {state: false, data: ''}, 
  USER_INFO_MODAL: {state: false, data: ''}, 
  ORDER_SUCCESS_MODAL: {state: false, data: ''}, 
  ORDER_CANCELLED_MODAL: {state: false, data: ''},
  RETURN_POLICY_MODAL: {state: false, data: ''},

  ALERT_MODAL: {state: false, data: ''},
  PRESCRIPTION_MODAL: {state: false, data: ''},
  LOCATION_MODAL: {state: false, data: ''},
};

const modalReducer = (state=modals, action) => {  
  if (action.type === 'MODAL') {
    return {...state, [action.payload.name]: {state: action.payload.status, data: action.payload.data}};
  }    
  return state;  
}

const IsMobileReducer = (isMobile=false, action) => {                  // Holds Status of viewving device.
  if (action.type === 'IS_MOBILE') {
    return action.value;
  }
  return isMobile;
}
// let isReferred = (queryString.referrer && queryString.referrer === 'true') ? true : false;
const isHeaderActive = (state=true, action) => {                       // To imitate pages without header/footer.
  if (action.type === 'HEADER_ACTIVE') {
    return action.value;
  }
  return state;
}

const getUserLocation = () => {
  const userLocation = JSON.parse(localStorage.getItem(`userLocation_${compCode}`));              
  if (userLocation && userLocation.LocationId) {
    return userLocation;
  } else {
    return { LocationId: 0 };
  }
}

const globalData = {
  focusArea: '0',
  location: getUserLocation(),
  scrollPos: { home: '', filterPage: '' },
  prescription: {}
};
const globalDataReducer = (state=globalData, action) => {                
  if (action.type === 'OTHER_DATA') {                                
    let newState = Object.assign(state, action.payload);                
    return {...newState};                                               
  }                                                                    
  return state;                                                                                                                                                                  
}


// TAKEHOME -------------------------------------------------------------------------------------

const filterCategoryReducer = (state={categories: [], selectedCategoryId: ''}, action) => {             // Added only to pass categories in select element used in header search input.
  if (action.type === 'FILTER_CATEGORIES') {
    return {...state, [action.payload.name]: action.payload.value};
  }
  return state;
}

const breadCrumbReducer = (state={links: [{name: 'Home', link: '/'}], activeLink: '/'}, action) => {
  if (action.type === 'BREADCRUMB_DATA') {
    return action.payload;
  }
  return state;
}

const quickviewItemReducer = (state={}, action) => {       // Controls the Toast state and data.
  if (action.type === 'QUICKVIEW_ITEM') {
    return action.payload;
  }
  return state;
}




export default combineReducers({
  compCode: compCodeReducer,
  isLoggedIn: loginStatusReducer,
  userInfo: userInfoReducer,
  isMobile: IsMobileReducer,
  compInfo: companyInfoReducer,
  isToastActive: toastReducer,
  isLoading: loaderReducer,
  cart: cartReducer,
  wishlist: wishlistReducer,
  siteData: siteDataReducer,
  modals: modalReducer,
  bookingInfo: bookingDataReducer,
  isHeaderActive: isHeaderActive,
  globalData: globalDataReducer,


  breadCrumbData: breadCrumbReducer,            // TAKEHOME
  quickviewItem: quickviewItemReducer,
  filterCategories: filterCategoryReducer,
})
